import { useQueries } from "@tanstack/react-query";

import { isGenericInfo } from "../model/OG/ExecutionTaskType";
import {
  InfoFieldPhotoDTO,
  isOfTypePhoto,
} from "../model/OG/InfoFieldPhotoDTO";
import { OGExecutionGenericInfo } from "../model/OG/OGExecutionGenericInfo";
import { useGlobalContext } from "../contexts/GlobalContext";
import { sortByProperty } from "../utils/Utils";
import {
  MAX_SNAPSHOTS_FOR_ACTIVATIONS_COUNT,
  QueryKeys,
} from "../utils/constants";
import TimelineService from "../services/TimelineService";

const useRequestSnapshot = () => {
  const {
    stateService: { currentOgExecution },
  } = useGlobalContext();

  const isSnapshotRequested = (infoField: InfoFieldPhotoDTO) => {
    return infoField.fieldPhotoStatus === "REQUESTED";
  };

  const getSnapshotsToRequest = () => {
    return currentOgExecution.value?.registryResponse.stepsList
      .sort(sortByProperty("clearedTimeStamp", true))
      .filter((f) => isGenericInfo(f) && f.infoFieldDTOs.some(isOfTypePhoto))
      .slice(-MAX_SNAPSHOTS_FOR_ACTIVATIONS_COUNT)
      .reduce<Record<string, number[]>>(
        (data, step: OGExecutionGenericInfo) => {
          const payload = step.infoFieldDTOs.reduce((acc, curr, index) => {
            if (isOfTypePhoto(curr) && !isSnapshotRequested(curr)) {
              acc.push(index);
            }
            return acc;
          }, []);
          if (payload.length) data[step.id] = payload;
          return data;
        },
        {}
      );
  };

  const snapshotsToRequest = Object.entries(getSnapshotsToRequest() || {});
  const executionId = currentOgExecution.value?.registryResponse.id;

  useQueries({
    queries: snapshotsToRequest.map(([stepId, indexes]) => ({
      queryKey: [QueryKeys.snapshot, executionId, stepId],
      enabled: snapshotsToRequest.length > 0,
      queryFn: () => TimelineService.getSnapshots(executionId, stepId, indexes),
    })),
  });
};

export default useRequestSnapshot;
