// General
import React, { useEffect, useState } from "react";
// Components
import { DMSImageGalleryData, ImageGallery as DMSImageGallery } from "dms-lib";
// Model
import { InfoFieldPhotoShort } from "../../../model/OG/InfoFieldPhotoDTO";
// Helpers
import { buildCaption } from "../../../helpers/captionHelper";
import "./imageGallery.module.scss";
import TimelineService from "../../../services/TimelineService";

interface ImageGalleryProps {
  mediaFileShort: InfoFieldPhotoShort;
  isOpen: boolean;
  onClose: () => void;
}

type DMSImageGalleryDataMap = { [key: string]: DMSImageGalleryData };
const ImageGallery = ({
  mediaFileShort,
  isOpen,
  onClose,
}: ImageGalleryProps): JSX.Element => {
  const { fileId } = mediaFileShort;
  const [displayImagesMap, setDisplayImagesMap] =
    useState<DMSImageGalleryDataMap>({
      [fileId]: {
        hasError: false,
        isLoading: true,
        src: "",
      },
    });

  const loadImage = async (): Promise<any> => {
    try {
      const content = await TimelineService.getMediaContentById(fileId);
      setDisplayImagesMap((prevValue: DMSImageGalleryDataMap) => ({
        ...prevValue,
        [fileId]: {
          src: URL.createObjectURL(content),
          hasError: false,
          isLoading: false,
          caption: buildCaption(mediaFileShort, "\n"),
        },
      }));
    } catch (error) {
      setDisplayImagesMap((prevValue: DMSImageGalleryDataMap) => ({
        ...prevValue,
        [fileId]: {
          ...prevValue.fileId,
          hasError: true,
          isLoading: false,
        },
      }));
    }
  };

  useEffect(() => {
    loadImage();
  }, []);

  return (
    <DMSImageGallery
      imagesData={displayImagesMap}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export default ImageGallery;
