import { HttpClient } from "compass-shared-services";
import { getUrl, MediaInfo, normalizeAPIObject } from "compass-commons";
import { OGExecutionComment } from "../model/OG/OGExecutionComment";
import { CreateCommentRequestDTO } from "../model/OG/CreateCommentRequestDTO";
import { OGExecutionResourceInteraction } from "../model/OG/OGExecutionResourceInteraction";
import { OGResourceInteractionRequest } from "../model/OG/OGResourceInteractionRequest";
import { OGExecutionTaskStepBase } from "../model/OG/OGExecutionTaskStepBase";
import { OGExecutionRegistry } from "../model/OG/OGExecutionRegistry";

const { TIMELINE_SERVICE_PATH } = appConfig;
const httpClient = new HttpClient(appConfig);
const URL_PATHS = {
  EXECUTION: `${TIMELINE_SERVICE_PATH}/execution/(id)`,
  EXECUTION_STEP: `${TIMELINE_SERVICE_PATH}/execution/(id)/(stepId)`,
  GET_MEDIA_FILE: `${TIMELINE_SERVICE_PATH}/media-files/(mediaFileId)`,
  GET_MEDIA_FILE_CONTENT: `${TIMELINE_SERVICE_PATH}/media-files/(mediaFileId)/content`,
};

/**
 * Timeline Service where backend service is called
 * for Timeline operations
 */
export default class TimelineService {
  static async getExecutionById(id: string): Promise<OGExecutionRegistry> {
    const urlPath = getUrl(URL_PATHS.EXECUTION, { id });
    return httpClient
      .get<OGExecutionRegistry>({ url: urlPath })
      .then((response) => {
        return normalizeAPIObject(response);
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getExecutionStepById(
    executionId: string,
    stepId: string
  ): Promise<OGExecutionTaskStepBase> {
    const urlPath = getUrl(URL_PATHS.EXECUTION_STEP, {
      id: executionId,
      stepId,
    });
    return httpClient
      .get<OGExecutionTaskStepBase>({ url: urlPath })
      .then((response) => {
        return normalizeAPIObject(response);
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async addCommentToExecution(
    id: string,
    comment: string
  ): Promise<OGExecutionComment> {
    const urlPath = `${getUrl(URL_PATHS.EXECUTION, { id })}/comments`;
    const ogComment: CreateCommentRequestDTO = {
      comment,
    };
    return httpClient
      .post<OGExecutionComment>({
        url: urlPath,
        payload: ogComment,
      })
      .then((response) => {
        return normalizeAPIObject(response);
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async addInteractionToExecution(
    executionId: string,
    interaction: OGResourceInteractionRequest
  ): Promise<OGExecutionResourceInteraction> {
    const urlPath = `${getUrl(URL_PATHS.EXECUTION, {
      id: executionId,
    })}/resource-interaction`;

    return httpClient
      .put<OGExecutionResourceInteraction>({
        url: urlPath,
        payload: interaction,
      })
      .then((response) => {
        return normalizeAPIObject(response);
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getSnapshots(
    executionId: string,
    stepId: string,
    payload: number[]
  ): Promise<OGExecutionTaskStepBase> {
    const url = `${getUrl(URL_PATHS.EXECUTION, {
      id: executionId,
    })}/${stepId}/request-snapshots`;

    return httpClient
      .put<OGExecutionTaskStepBase>({ url, payload })
      .then((response) => {
        return normalizeAPIObject(response);
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getMediaById(mediaFileId): Promise<MediaInfo> {
    const uri = {
      mediaFileId,
    };
    const urlPath = getUrl(URL_PATHS.GET_MEDIA_FILE, uri);
    return httpClient
      .get<MediaInfo>({
        url: urlPath,
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getMediaContentById(mediaFileId): Promise<any> {
    const uri = {
      mediaFileId,
    };
    const urlPath = getUrl(URL_PATHS.GET_MEDIA_FILE_CONTENT, uri);
    return httpClient
      .get<any>({
        url: urlPath,
        config: {
          responseType: "blob",
        },
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }
}
