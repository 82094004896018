export const COMMENTS_MAXIMUM_CHARACTERS = 256;
export const MAX_SNAPSHOTS_FOR_ACTIVATIONS_COUNT = 10;

// FEATURES KEYS

export const SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG =
  "b_showGenericErrorMessages";

/*---------------------------------------------------
        Query keys ...used in making requests
 --------------------------------------------------*/
export const QueryKeys = {
  mediaById: "mediaById",
  registryExecutionById: "registryExecutionById",
  engineExecutionById: "engineExecutionById",
  snapshot: "snapshot",
  executionStepId: "executionStepId",
};
