import React from "react";

import {
  Incident,
  parseDate,
  ResourceInteractionSource,
} from "compass-commons";
import { TaskStepState } from "../model/OG/TaskStepState";
import GenericTaskStep from "../components/operatorGuide/execution/taskStep/GenericTaskStep";
import { OGExecutionTaskStepBase } from "../model/OG/OGExecutionTaskStepBase";
import { ResourceCommandAdditionalInfo } from "../model/resource/ResourceCommand";
import { OGExecutionComment } from "../model/OG/OGExecutionComment";
import StateService from "../services/StateService";
import { OGExecutionResourceInteraction } from "../model/OG/OGExecutionResourceInteraction";
import { ResourceMapping } from "../model/resource/ResourceMapping";
import { OGResourceInteractionRequest } from "../model/OG/OGResourceInteractionRequest";
import { FunctionalityState } from "../model/resource/FunctionalityState";
import { OGExecutionTask } from "../model/OG/OGExecutionTask";
import { BlockTypes } from "../model/OG/BlockTypes";
import { mapSelectedOptionDtoToInternalSelectedOption } from "../components/operatorGuide/execution/taskStep/taskBlocks/databaseSearch/Util";
import DatabaseSearchResult from "../model/databaseSearch/internal/DatabaseSearchResult";
import TimelineService from "../services/TimelineService";

const mapTask = (
  index: any,
  executionId: string,
  task: OGExecutionTaskStepBase,
  taskState: TaskStepState,
  resourceCommandAdditionalInfo: ResourceCommandAdditionalInfo,
  incident: Incident
) => {
  if (!task) {
    return <React.Fragment key={index} />;
  }

  return (
    <GenericTaskStep
      key={task.id}
      executionId={executionId}
      task={task}
      taskState={taskState}
      resourceCommandAdditionalInfo={resourceCommandAdditionalInfo}
      incident={incident}
    />
  );
};

const getDateInfo = (taskState: TaskStepState, task) => {
  let dateInfo = null;
  if (TaskStepState.COMPLETED === taskState) {
    if (task.clearedTimeStamp) {
      dateInfo = parseDate(task.clearedTimeStamp);
    }
    const comment = task as OGExecutionComment;
    if (comment && comment.creationTimeStamp && !comment.clearedTimeStamp) {
      dateInfo = parseDate(comment.creationTimeStamp);
    } else {
      dateInfo = parseDate(comment.clearedTimeStamp);
    }
  }

  return dateInfo;
};

const addPerformedResourceInteraction = (
  stateService: StateService,
  interaction: OGExecutionResourceInteraction
) => {
  if (interaction) {
    const executionSubject = stateService?.currentOgExecution;
    const execution = executionSubject && { ...executionSubject.value };
    const stepsList = execution?.registryResponse?.stepsList;
    if (stepsList) {
      stepsList.push(interaction);
      execution.registryResponse.stepsList = stepsList;
      executionSubject.next(null);
      executionSubject.next(execution);
    }
  }
};

const performTaskExecution = (
  stateService: StateService,
  taskId: string,
  executionId: string,
  resource: ResourceMapping,
  functionalityId: string,
  interactionSource: ResourceInteractionSource
) => {
  const resourceInteractionRequest: OGResourceInteractionRequest = {
    taskId,
    resourceId: resource.resourceId,
    resourceMappingId: resource.id,
    resourceName: resource.name,
    functionalityId,
    interactionSource,
  } as OGResourceInteractionRequest;
  TimelineService.addInteractionToExecution(
    stateService?.currentExecutionId?.value || executionId,
    resourceInteractionRequest
  )
    .then((r) => {
      addPerformedResourceInteraction(stateService, r);
    })
    .catch((err) => console.error(err));
};

const getResourceStateString = (
  translate: (string) => string,
  resourceState: FunctionalityState
) => {
  return `${translate("resources.state")}: ${
    resourceState && resourceState.name ? resourceState.name : "Default"
  }`;
};

const getResourceInteractionSourceText = (
  translate: (string: string) => string,
  interactionSource: ResourceInteractionSource
) => {
  switch (interactionSource) {
    case ResourceInteractionSource.SITE_LAYOUT: {
      return translate("resources.interactionTypeSiteLayout");
    }
    case ResourceInteractionSource.RELATED_RESOURCE: {
      return translate("resources.interactionTypeRelatedResource");
    }
    case ResourceInteractionSource.OPERATOR_GUIDE_TASK: {
      return translate("resources.interactionTypeOperatorGuideTask");
    }
    default: {
      return translate("resources.interactionTypeOperatorGuideTask");
    }
  }
};

const extractSelectedOptionFromDbSearchTask = (
  task: OGExecutionTask,
  taskState: TaskStepState
): DatabaseSearchResult => {
  if (taskState !== TaskStepState.COMPLETED) return null;

  const { selectedOption } = task.typeBlocks.find(
    (block) => block.type === BlockTypes.Dropdown
  );

  return mapSelectedOptionDtoToInternalSelectedOption(selectedOption);
};

// eslint-disable-next-line import/prefer-default-export
export {
  mapTask,
  getDateInfo,
  addPerformedResourceInteraction,
  performTaskExecution,
  getResourceStateString,
  getResourceInteractionSourceText,
  extractSelectedOptionFromDbSearchTask,
};
