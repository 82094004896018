// Generic
import React from "react";
import { useQuery } from "@tanstack/react-query";
import {
  isMediaAvailable,
  isMediaWaiting,
  LocalizationNS,
  MediaFileStatus,
  MediaInfo,
  useI18n,
} from "compass-commons";
// Styles
import "./infoFieldPhoto.module.css";
// Components
import { Image } from "dms-lib";
// Models
import { InfoFieldPhotoDTO } from "../../../../../../model/OG/InfoFieldPhotoDTO";
// Services
// Helpers
import { buildCaption } from "../../../../../../helpers/captionHelper";
import { QueryKeys } from "../../../../../../utils/constants";
import TimelineService from "../../../../../../services/TimelineService";

const InfoFieldPhoto = ({
  infoFieldPhoto: imageData,
  onImgClick,
}: {
  infoFieldPhoto: InfoFieldPhotoDTO;
  onImgClick?: (val: MediaInfo) => void;
}) => {
  const { t: translate } = useI18n();
  const isRequested = imageData.fieldPhotoStatus === "REQUESTED";

  const isWaitingToUpload = (file: MediaInfo) => {
    return !file || isMediaWaiting(file.mediaFileStatus);
  };

  const { isError, data } = useQuery(
    [QueryKeys.mediaById, imageData.fileId],
    () => TimelineService.getMediaById(imageData.fileId),
    {
      initialData: imageData.mediaFileDTO || undefined,
      enabled: isRequested && isWaitingToUpload(imageData.mediaFileDTO),
      refetchInterval: (res) => {
        if (isWaitingToUpload(res)) return 1000;
        return false;
      },
    }
  );

  const mediaFile: MediaInfo = (imageData.mediaFileDTO || data) && {
    ...(data || imageData.mediaFileDTO),
    thumbnail: {
      ...(data?.thumbnail || imageData.mediaFileDTO?.thumbnail),
    },
    mediaFileStatus:
      (isError && MediaFileStatus.UPLOAD_FAILED) ||
      data?.mediaFileStatus ||
      imageData.mediaFileDTO?.mediaFileStatus,
  };

  const mediaFailed = !(
    isRequested && isMediaAvailable(mediaFile?.mediaFileStatus)
  );

  const mediaPolling = isRequested && isWaitingToUpload(mediaFile);

  const mediaCaption = buildCaption(
    {
      fileId: imageData.fileId,
      resourceMappingName: imageData.resourceMapping?.name,
      mediaTimestamp: mediaFile?.mediaTimestamp || imageData.desiredTimestamp,
    },
    "\n"
  );

  return (
    <Image
      id={imageData.fileId}
      isRequested={isRequested}
      src={`data:${mediaFile?.thumbnail?.mediaType};base64,${mediaFile?.thumbnail?.content}`}
      alt="addition info"
      imageClassName="operation-info-field__photo"
      hasError={mediaFailed}
      isLoading={mediaPolling}
      caption={mediaCaption}
      onClick={() => onImgClick(mediaFile)}
      placeholderText={translate("getImage", {
        ns: LocalizationNS.SHARED,
      })}
    />
  );
};

export default InfoFieldPhoto;
